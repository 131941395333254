$textcolorvalidatepurchase: #000;
.validate-purchase-div {
	width: 100%;
	padding: 5%;
	.h2-title {
		text-align: center;
		color: $textcolorvalidatepurchase;
		text-transform: uppercase;
		text-decoration: none !important;
	}
	h4{margin-bottom: 25px;}
	.h2-disc1 {
		text-align: center;
		font-weight: bold;
		font-size: 1.25rem;
	}
	.label-disc {
		text-align: center;
		font-weight: bold;
	}
	.uploade-div-container {
		text-align: center;
		width: 100%;
		padding: 20px;
	}
	.custom-file {
		padding: 20px 40px 40px;
		width: 100%;
		max-width: 400px;
		text-align: center;
		border: 1px solid;
		vertical-align: middle;
		margin: 0 auto;
		cursor: pointer;
	}
	.btn-div {
		text-align: center;
		width: 100%;
	}
	.receipt-preview {
		margin: 0 auto;
		width: 100%;
		max-width: 400px;
		padding-bottom: 25px;
	}
	.receipt-preview img {
		width: 100%;
		height: auto;
		max-height: 400px;
	}
	.label-link {
		text-align: center;
		color: $textcolorvalidatepurchase;
		font-weight: bold;
		cursor: pointer;
		text-decoration: underline;
		font-size: large;
		line-height: 3;
	}
	#href-reset {
		font-weight: bold;
		line-height: 5;
		font-size: 20px;
	}
	.response-message-div {
		margin-bottom: 5px;
		border-radius: 10px;
		padding: 10px;
	}
	.validate-purchase-href-site-link {
		text-decoration: underline;
		font-weight: bold;
	}
}
.validate-purchase-div-modal-image {
	width: 95%;
}
.validate-purchase-href-model-link {
	text-decoration: underline;
	font-weight: bold;
}
.validate-purchase-div-modal-image {
	width: 95%;
}
.validate-purchase-href-model-link {
	text-decoration: underline;
	font-weight: bold;
}
